import {
  WALLET_DATA_UPDATE,
  WALLET_BALANCE,
  WALLET_ADDRESS,
  WALLET_ADDRESS_PATH0,
  WALLET_MNEMONIC,
  WALLET_HISTORY,
  WALLET_PAYMAIL,
  WALLET_DATA_DESTROY
} from "../actions/actionsMain";

const initState = {
  walletData: null,
  balance: 0,
  address: "",
  addressPath0: "",
  mnemonic: "",
  wallethistory: [],
  walletid: "00000000-0000-0000-0000-000000000000",
  paymail: "",
};

const walletReducer = (state = initState, action) => {
  if (action.type === WALLET_DATA_UPDATE) {
    return {
      ...state,
      walletData: action.payload,
    };
  } else if (action.type === WALLET_HISTORY) {
    return {
      ...state,
      wallethistory: action.payload,
    };
  }
  else if (action.type === WALLET_DATA_DESTROY) {
    return {
      walletData: null,
      balance: 0,
      address: "",
      addressPath0: "",
      mnemonic: "",
      wallethistory: [],
      walletid: "00000000-0000-0000-0000-000000000000",
      paymail: "",
    };
  } else if (action.type === WALLET_MNEMONIC) {
    return {
      ...state,
      mnemonic: action.payload,
    };
  } else if (action.type === WALLET_BALANCE) {
    return {
      ...state,
      balance: action.payload,
    };
  } else if (action.type === WALLET_ADDRESS) {
    return {
      ...state,
      address: action.payload,
    };
  } else if (action.type === WALLET_ADDRESS_PATH0) {
    return {
      ...state,
      addressPath0: action.payload,
    };
  } else if (action.type === WALLET_PAYMAIL) {
    return {
      ...state,
      paymail: action.payload,
    };
  } else {
    return state;
  }
};

export default walletReducer;
