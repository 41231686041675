import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import {
  USER_DATA_UPDATE,
  USER_FOLLOWING_ARTISTS_UPDATE,
  SHOW_USERNAME_DIALOG,
  USER_DATA_LOADING,
  USER_AUTH_TOKEN,
} from '../actions/actionsMain';

const initState = {
  userData: null,
  followingArtists: [],
  userLoading: true,
  isUsernameDialog: false,
  userauthtoken: null,
  isUserDataFetched: false,
};

const authReducer = (state = initState, action) => {
  if (action.type === USER_DATA_UPDATE) {
    return {
      ...state,
      userData: action.payload,
      userLoading:
        state.userLoading && action.payload ? false : state.userLoading,
      isUserDataFetched: true,
    };
  } else if (action.type === USER_AUTH_TOKEN) {
    return {
      ...state,
      userauthtoken: action.payload,
    };
  } else if (action.type === USER_FOLLOWING_ARTISTS_UPDATE) {
    return {
      ...state,
      followingArtists: [...action.payload],
    };
  } else if (action.type === USER_DATA_LOADING) {
    return {
      ...state,
      userLoading: action.payload,
    };
  } else if (action.type === SHOW_USERNAME_DIALOG) {
    return {
      ...state,
      isUsernameDialog: action.payload,
    };
  } else {
    return state;
  }
};

export default authReducer;
