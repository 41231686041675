import {
  UPDATE_HANDCASH_PROFILE,
  UPDATE_HANDCASH_BALANCE,
} from '../actions/actionsMain';

const initState = {
  balance: null,
  profile: null,
};

const handCashReducer = (state = initState, action) => {
  if (action.type === UPDATE_HANDCASH_PROFILE) {
    return {
      ...state,
      profile: action.payload,
    };
  } else if (action.type === UPDATE_HANDCASH_BALANCE) {
    return {
      ...state,
      balance: action.payload,
    };
  } else {
    return state;
  }
};

export default handCashReducer;
