import { AUDIO_UPDATE } from '../actions/actionsMain';

const initState = {
  audioStatus: null,
};

const audioReducer = (state = initState, action) => {
  switch (action.type) {
    case AUDIO_UPDATE:
      return {
        ...state,
        audioStatus: action.payload,
      };

    default:
      return state;
  }
};

export default audioReducer;
