import React, { useState, useEffect } from 'react';
// import App from "next/app";
import Head from 'next/head';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { wrapper } from '../store/index';
import { ToastContainer } from 'react-toastify';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'react-phone-number-input/style.css';
import 'lightgallery.js/dist/css/lightgallery.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/tailwind.css';
import 'react-jinke-music-player/assets/index.css';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/global.css';
import '../styles/responsive.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-virtualized/styles.css';

import { LightgalleryProvider } from 'react-lightgallery';
// import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { httpsCallable } from 'firebase/functions';

import { getAnalytics, initializeAnalytics } from 'firebase/analytics';
import GetCurrentUser from '../components/Layouts/GetCurrentUser';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { envMODE } from '../config/envMode';
import { apiconfiger } from '../config/apiConfig';
import { Backdrop, CircularProgress, IconButton } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { firebaseApp } from '../config/firebase';
import { firebaseFireStore } from '../config/firebaseFirestore';
import { getDocs, collection } from 'firebase/firestore/lite';
import Script from 'next/script';
import * as fbq from '../lib/fpixel';
import { async } from '@firebase/util';
import { firebaseFunctions } from '../config/firebaseFunctions';
import { useDispatch } from 'react-redux';
import {
  updateHandCashProfile,
  updateHandCashBalance,
} from '../store/actions/actionsMain';

if (envMODE === 'DEV') {
  apiconfiger.defaults.headers.common['serviceId'] =
    '41cee713-3e90-4a7c-85a0-427673f2aa70';
} else if (envMODE === 'STAGING') {
  apiconfiger.defaults.headers.common['serviceId'] =
    '987912d3-d7df-4b55-aa6f-72deedba4cd5';
} else if (envMODE === 'PROD') {
  apiconfiger.defaults.headers.common['serviceId'] =
    '174b40d7-5d2d-462c-85b8-6a2b58a6dab2';
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9700',
    },
    secondary: {
      main: '#1E293B',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
  },
});

const MyApp = ({ Component, pageProps, ...props }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [backDropLoading, setbackDropLoading] = useState(false);

  useEffect(() => {
    initializeAnalytics(firebaseApp);
    getAnalytics(firebaseApp);
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const start = (url) => {
      setbackDropLoading(true);
    };

    const end = (url, url2, url3, url4) => {
      setbackDropLoading(false);

      if (!Router.pathname.includes('/nft')) {
        console.log('Scroll to top');
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (router?.query?.authToken) {
      const getUserHandCashWallet = async () => {
        try {
          let handCashConnectApi = httpsCallable(
            firebaseFunctions,
            'connectHandCashWallet',
          );

          let apiRes = await handCashConnectApi({
            authToken: router.query.authToken,
          });

          console.log('apiRes', apiRes);

          if (apiRes?.data?.status === 'success' && apiRes?.data?.data) {
            dispatch(updateHandCashProfile(apiRes.data.data.currentProfile));
            dispatch(updateHandCashBalance(apiRes.data.data.balance));
          } else {
            return null;
          }
        } catch (err) {
          console.log('handcash err', err.message, err);
        }
      };

      getUserHandCashWallet();
    }
  }, [router.query]);

  return (
    <React.Fragment>
      <Script id="facebook-pixel">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${fbq.FB_PIXEL_ID});
        fbq('track', 'PageView');
      `}
      </Script>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>MusicArt</title>
        {/* <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"></script> */}
        {
          // <script
          //   id="usercentrics-cmp"
          //   data-settings-id="kGCa6jvpg"
          //   className="usercentrics"
          //   src="https://app.usercentrics.eu/browser-ui/latest/bundle.js"
          //   async
          // ></script>
        }
        {/* <Link  rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link> */}
        {/* <link
            href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
            rel="stylesheet"
          ></link> */}
      </Head>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="I ACCEPT"
          cookieName="Musicartcookies"
          enableDeclineButton
          flipButtons
          buttonWrapperClasses="flex items-center "
          containerClasses="cookies-banner"
          style={{
            background: 'white',
            color: 'black',
            lineHeight: 1.5,
          }}
          contentClasses="cookiecontent"
          buttonStyle={{ color: 'white', background: '#ff9700' }}
        >
          <div style={{ display: 'block' }}>
            This website uses cookies to enhance the user experience.
            <Link prefetch={false} href="/privacy-policy">
              <a className="linkcookie cursor-pointer">
                {' '}
                View Our Privacy Policy
              </a>
            </Link>
            .{' '}
          </div>
        </CookieConsent>
        {/* {closeNotification == true && !router.pathname.includes('admin') ? (
          <>
            {notification &&
              notification
                .filter((x) => x.isActive)
                .splice(0, 1)
                .map((y) => (
                  <div
                    className="items-center text-black flex max-h-[70px] top-0 md:py-3 fle-wrap justify-between left-0 sticky w-full"
                    style={{
                      zIndex: '100',
                      background: '#F16604',
                      height: '50px',
                    }}
                  >
                    <div className="container mx-auto p-0 text-center ymessage ">
                      <div className="flex items-center justify-center h-full text-xs  md:text-lg text-white">
                        <p className="px-5">{y.message}</p>
                        <CloseIcon
                          className="cursor-pointer close_icon_notification"
                          onClick={(e) => setCloseNotification(false)} 
                        />
                      </div>
                    </div>
                  </div>
                ))}
          </>
        ) : (
          ''
        )} */}

        <ThemeProvider theme={theme}>
          <LightgalleryProvider
            plugins={['lg-fullscreen.js', 'lg-video.js', 'lg-zoom.js']}
            lightgallerySettings={{
              counter: false,
              download: false,
            }}
          >
            <LocalizationProvider dateAdapter={MomentUtils}>
              <GetCurrentUser />
              {backDropLoading && (
                <div
                  style={{ position: 'absolute', zIndex: '1000000' }}
                  // id="loadingopen-component"
                >
                  <Backdrop open={true}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              <Component {...pageProps} />
            </LocalizationProvider>
          </LightgalleryProvider>
        </ThemeProvider>
      </div>
      <ToastContainer
        style={{ zIndex: 9990 }}
        // limit={1}
      />
    </React.Fragment>
  );
};

export default wrapper.withRedux(MyApp);
