import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import apiConfig from '../../config/apiConfig';
import {
  updateUserDataAction,
  getBsvRate,
  getEthRate,
  updateUserLoadingAction,
  updateUserFollowingArtistsAction,
  //new  wallet integeratin functions
  setUserAuthTOken,
} from '../../store/actions/actionsMain';
import { updateRoomsDataAction } from '../../store/actions/nftActions';
import { useRouter } from 'next/router';
import {
  getwalletDetails,
  getHandCashWalletDetails,
} from '../../services/common-query';
import { onIdTokenChanged } from 'firebase/auth';
import { firebaseAuthFunc } from '../../config/firebaseAuth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  query,
  where,
} from 'firebase/firestore/lite';
import { firebaseFireStore } from '../../config/firebaseFirestore';

function GetCurrentUser() {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    try {
      //get bsv rate
      console.log('refetch');
      dispatch(updateUserLoadingAction(true));
      getBsvRateFunc();
      getEthRateFunc();
      getRoomsDataFunc();

      onIdTokenChanged(firebaseAuthFunc, (user) => {
        // console.log("user change", user)
        if (user) {
          //getting artists id followed by user
          getFollowingArtists(user.uid);

          if (user.emailVerified) {
            updateSellerEmailStatus(user.uid);
          }
          // <------ Check this line
          console.log('usertoken', user.accessToken);
          apiConfig.defaults.headers.common['authToken'] = user.accessToken;
          dispatch(updateUserDataAction(user));
          dispatch(updateUserLoadingAction(false));
          dispatch(setUserAuthTOken(user.accessToken));
          // console.log("for get user side");
          getwalletDetails(
            '00000000-0000-0000-0000-000000000000',
            dispatch,
            user.uid,
          );
          getHandCashWalletDetails(user.uid, dispatch);
        } else {
          dispatch(updateUserDataAction(null));
        }

        dispatch(updateUserLoadingAction(false));
      });
    } catch (err) {
      console.log('err d', err.message, err.response);
    }
  }, []);

  const updateSellerEmailStatus = async (uid) => {
    const docRef = doc(firebaseFireStore, 'musicartSellers', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      updateDoc(docRef, { emailStatus: true });
    }
  };

  const getBsvRateFunc = async () => {
    let bsvData = await getDoc(doc(firebaseFireStore, 'exchangeRates', 'bsv'));
    if (bsvData.exists) {
      if (bsvData.data() && bsvData.data().rate) {
        dispatch(getBsvRate(Number(bsvData.data().rate)));
      }
    }
  };

  const getEthRateFunc = async () => {
    let bsvData = await getDoc(doc(firebaseFireStore, 'exchangeRates', 'eth'));
    if (bsvData.exists) {
      if (bsvData.data() && bsvData.data().rate) {
        dispatch(getEthRate(Number(bsvData.data().rate)));
      }
    }
  };

  const getRoomsDataFunc = async () => {
    let dataArr = [];
    const roomData = await getDocs(
      query(
        collection(firebaseFireStore, 'roomDetails'),
        where('isActive', '==', true),
      ),
    );

    roomData.forEach((x) => {
      dataArr.push({ ...x.data(), id: x.id });
    });

    if (dataArr.length > 0) {
      dispatch(updateRoomsDataAction([...dataArr]));
    }
  };

  const getFollowingArtists = async (uid) => {
    let dataRef = await getDocs(
      collection(firebaseFireStore, 'musicartSellers', uid, 'followingArtists'),
    );
    if (dataRef.empty) {
      return null;
    }

    let dataArr = [];
    dataRef.forEach((doc) => {
      dataArr.push(doc.id);
    });
    dispatch(updateUserFollowingArtistsAction(dataArr));
  };

  return <></>;
}

export default GetCurrentUser;
