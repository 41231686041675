import { doc, getDoc } from 'firebase/firestore/lite';
import { firebaseFireStore } from '../../config/firebaseFirestore';

export const GET_NFT_DATA = 'GET_NFT_DATA';
export const UPDATE_ROOMS_DATA = 'UPDATE_ROOMS_DATA';

export const getNftDataAction = (nftId) => {
  return async (dispatch) => {
    let summary = await getDoc(doc(firebaseFireStore, 'musicartNfts', nftId));

    let details = await getDoc(
      doc(firebaseFireStore, 'musicartNfts', nftId, 'nftData', 'details'),
    );

    dispatch(setNftDataAction({ ...summary.data(), ...details.data() }));
  };
};

export const setNftDataAction = (data) => ({
  type: GET_NFT_DATA,
  payload: data,
});

export const updateRoomsDataAction = (data) => ({
  type: UPDATE_ROOMS_DATA,
  payload: data,
});
