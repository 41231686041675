import { envMODE } from './envMode';
import { initializeApp } from 'firebase/app';
import apiConfig from './apiConfig';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

//DEV or PROD
let firebaseConfig;
let defaultUserDb;
let defaultWalletDb;
let nftsFirebaseStorageUrl;
let newbucketlink1;
let newbucketlink2;

const firebasestaging = {
  apiKey: 'AIzaSyBIJ3z_WNMtUbnmzslFbRseL7X3kfbWBeQ',
  authDomain: 'musicartstaging.firebaseapp.com',
  databaseURL: 'https://musicartstaging-default-rtdb.firebaseio.com',
  projectId: 'musicartstaging',
  storageBucket: 'musicartstaging.appspot.com',
  messagingSenderId: '481838562550',
  appId: '1:481838562550:web:438c2c62efd3ce869a80d7',
  measurementId: 'G-94H4CCWJQV',
};
const firebasedev = {
  apiKey: 'AIzaSyB255gwdwQ-B8iMv_wvHSItuyKIInc_mwk',
  authDomain: 'musicartdev.firebaseapp.com',
  databaseURL: 'https://musicartdev-default-rtdb.firebaseio.com',
  projectId: 'musicartdev',
  storageBucket: 'musicartdev.appspot.com',
  messagingSenderId: '1032692364453',
  appId: '1:1032692364453:web:b647a84da0602d9e96c94c',
  measurementId: 'G-ZCC0HDEX1V',
};

const firebaseprod = {
  apiKey: 'AIzaSyA6bkzZtIG7fXmThhC8rxVvqmhxd_mA4Es',
  authDomain: 'musicart-146af.firebaseapp.com',
  databaseURL: 'https://musicart-146af-default-rtdb.firebaseio.com',
  projectId: 'musicart-146af',
  storageBucket: 'musicart-146af.appspot.com',
  messagingSenderId: '653754899941',
  appId: '1:653754899941:web:2b0946a9b10d3cde4ee30c',
  measurementId: 'G-0L9BKCMQXC',
};

let ReCaptchaV3KeyDev = '6LdQuUYjAAAAAGnli1JdQCWIG7JrDHs9oHkkdrTP';
let ReCaptchaV3KeyStag = '6LdAxkYjAAAAADjkK6NE-FDYuJ31XhPnK-fW_2Jv';
// let ReCaptchaV3KeyProd = '6Le4yEYjAAAAACocC2BWounNDEJnbrKE8Ov-hvAB';

let ReCaptchaV3Key = '';
if (envMODE === 'DEV') {
  defaultUserDb = 'https://musicartdev-default-rtdb.firebaseio.com';
  defaultWalletDb = 'https://musicartdev-wallet.firebaseio.com/';
  nftsFirebaseStorageUrl = 'gs://musicartdev';
  newbucketlink1 = `https://firebasestorage.googleapis.com/v0/b/musicartdev.appspot.com/o/`;
  newbucketlink2 = `https://firebasestorage.googleapis.com/v0/b/musicartdev/o/`;

  firebaseConfig = firebasedev;

  apiConfig.defaults.headers.common['serviceId'] =
    '41cee713-3e90-4a7c-85a0-427673f2aa70';

  ReCaptchaV3Key = ReCaptchaV3KeyDev;
} else if (envMODE === 'STAGING') {
  defaultUserDb = 'https://musicartstaging-default-rtdb.firebaseio.com';
  defaultWalletDb = 'https://wallet-stagging-musicart-temp.firebaseio.com/';
  newbucketlink1 = `https://firebasestorage.googleapis.com/v0/b/musicartstaging.appspot.com/o/`;
  newbucketlink2 = `https://firebasestorage.googleapis.com/v0/b/musicartstaging/o/`;
  nftsFirebaseStorageUrl = 'gs://musicartstaging';

  firebaseConfig = firebasestaging;

  apiConfig.defaults.headers.common['serviceId'] =
    '987912d3-d7df-4b55-aa6f-72deedba4cd5';

  ReCaptchaV3Key = ReCaptchaV3KeyStag;
} else if (envMODE === 'PROD') {
  defaultUserDb = 'https://musicart-146af-default-rtdb.firebaseio.com';
  defaultWalletDb = 'https://wallet-musicart.firebaseio.com/';
  nftsFirebaseStorageUrl = 'gs://musicart-146af/';
  newbucketlink1 = `https://firebasestorage.googleapis.com/v0/b/musicart-146af.appspot.com/o/`;
  newbucketlink2 = `https://firebasestorage.googleapis.com/v0/b/musicart-146af/o/`;

  firebaseConfig = firebaseprod;

  apiConfig.defaults.headers.common['serviceId'] =
    '174b40d7-5d2d-462c-85b8-6a2b58a6dab2';

  // ReCaptchaV3Key = ReCaptchaV3KeyProd;
}

export const firebaseApp = initializeApp(firebaseConfig);

if (typeof window !== 'undefined' && envMODE !== 'PROD') {
  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(ReCaptchaV3Key),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

export {
  defaultUserDb,
  defaultWalletDb,
  nftsFirebaseStorageUrl,
  newbucketlink1,
  newbucketlink2,
};
