import { BSV_RATE, ETH_RATE } from '../actions/actionsMain'

const initState = {
  bsvRate: null,
  ethRate: null,
}

const bsvRateReducer = (state = initState, action) => {
  if (action.type === BSV_RATE) {
    return {
      ...state,
      bsvRate: action.payload,
    }
  } else if (action.type === ETH_RATE) {
    return {
      ...state,
      ethRate: action.payload,
    }
  } else {
    return state
  }
}

export default bsvRateReducer
