import axios from "axios";
import { envMODE } from "./envMode";
const instance = axios.create({
  baseURL: "https://api.relysia.com",
});
const withoutheader = axios.create({
  baseURL: "https://api.relysia.com",
});

export const apiconfiger = withoutheader;
export default instance;
