//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const USER_DATA_UPDATE = 'USER_DATA_UPDATE';
export const USER_FOLLOWING_ARTISTS_UPDATE = 'USER_FOLLOWING_ARTISTS_UPDATE';
export const WALLET_DATA_DESTROY = 'WALLET_DATA_DESTROY';
export const WALLET_DATA_UPDATE = 'WALLET_DATA_UPDATE';
export const BSV_RATE = 'BSV_RATE';
export const ETH_RATE = 'ETH_RATE';

export const SHOW_USERNAME_DIALOG = 'SHOW_USERNAME_DIALOG';
export const USER_DATA_LOADING = 'USER_DATA_LOADING';

export const USER_AUTH_TOKEN = 'USER_AUTH_TOKEN';
export const WALLET_ADDRESS = 'WALLET_ADDRESS';
export const WALLET_PAYMAIL = 'WALLET_PAYMAIL';
export const WALLET_ADDRESS_PATH0 = 'WALLET_ADDRESS_PATH0';

export const WALLET_BALANCE = 'WALLET_BALANCE';
export const WALLET_MNEMONIC = 'WALLET_MNEMONIC';
export const WALLET_HISTORY = 'WALLET_HISTORY';

export const AUDIO_UPDATE = 'AUDIO_UPDATE';

export const UPDATE_HANDCASH_PROFILE = 'UPDATE_HANDCASH_PROFILE';
export const UPDATE_HANDCASH_BALANCE = 'UPDATE_HANDCASH_BALANCE';

export const destroyWalletData = (payload) => {
  return {
    type: WALLET_DATA_DESTROY,
    payload,
  };
};

export const updatewallethistory = (payload) => {
  return {
    type: WALLET_HISTORY,
    payload,
  };
};

export const updatewalletmnemonic = (payload) => {
  return {
    type: WALLET_MNEMONIC,
    payload,
  };
};
export const updateWalletAddress = (payload) => {
  return {
    type: WALLET_ADDRESS,
    payload,
  };
};
export const updateWalletPath0Address = (payload) => {
  return {
    type: WALLET_ADDRESS_PATH0,
    payload,
  };
};
export const updateWalletPaymail = (payload) => {
  return {
    type: WALLET_PAYMAIL,
    payload,
  };
};

export const updateWalletBalance = (payload) => {
  return {
    type: WALLET_BALANCE,
    payload,
  };
};
export const updateUserDataAction = (payload) => {
  return {
    type: USER_DATA_UPDATE,
    payload,
  };
};

export const setUserAuthTOken = (payload) => {
  return {
    type: USER_AUTH_TOKEN,
    payload,
  };
};

export const updateUserLoadingAction = (payload) => {
  return {
    type: USER_DATA_LOADING,
    payload,
  };
};

export const updateUserFollowingArtistsAction = (payload) => {
  return {
    type: USER_FOLLOWING_ARTISTS_UPDATE,
    payload,
  };
};

export const updateWalletDataAction = (payload) => {
  return {
    type: WALLET_DATA_UPDATE,
    payload,
  };
};

export const getBsvRate = (payload) => {
  return {
    type: BSV_RATE,
    payload,
  };
};

export const getEthRate = (payload) => {
  return {
    type: ETH_RATE,
    payload,
  };
};

export const showUsernameDialog = (payload) => {
  return {
    type: SHOW_USERNAME_DIALOG,
    payload,
  };
};

export const updateAudioState = (payload) => {
  return {
    type: AUDIO_UPDATE,
    payload,
  };
};

export const updateHandCashProfile = (payload) => {
  return {
    type: UPDATE_HANDCASH_PROFILE,
    payload,
  };
};

export const updateHandCashBalance = (payload) => {
  return {
    type: UPDATE_HANDCASH_BALANCE,
    payload,
  };
};
