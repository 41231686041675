import { GET_ADMIN_DATA, ACTIVE_ADMIN_NFT } from "../actions/adminActions";

const initState = {
  adminAuth: null,
  nftitems: null,
};

const adminReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_ADMIN_DATA:
            return {
                ...state,
                adminAuth: action.payload,
            };

        case ACTIVE_ADMIN_NFT:
            return {
                ...state,
                nftitems: action.payload,
            };
        default:
            return state;
    }
};

export default adminReducer;
