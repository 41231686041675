import { onIdTokenChanged } from "firebase/auth";
import { collection, doc, getDoc, orderBy, query, where } from "firebase/firestore/lite";

import { firebaseAuthFunc } from "../../config/firebaseAuth";
import { firebaseFireStore } from "../../config/firebaseFirestore";

export const GET_ADMIN_DATA = "GET_ADMIN_DATA";
export const ACTIVE_ADMIN_NFT = "ACTIVE_ADMIN_NFT";

export const adminAuthAction = () => {
  return async (dispatch) => {
    onIdTokenChanged(firebaseAuthFunc,  async(user,err) => {
      if (!user) {
        dispatch(adminAuthSuccess("unauthorized"));
      } else {
        let result = await getDoc(doc(firebaseFireStore,"admin","adminList")) 

        let data = result.data();

        if (
          data.admins &&
          data.admins.length !== 0 &&
          data.admins.includes(user.uid)
        ) {
          dispatch(adminAuthSuccess(user.uid));
        }
      }
    });
  };
};

export const adminAuthSuccess = (data) => ({
  type: GET_ADMIN_DATA,
  payload: data,
});

export const getActiveNftItems = () => {
  return async (dispatch) => {
    const q = query(collection(firebaseFireStore, "musicartNfts"),
     where("adminState", "==", "ACCEPT"),
     where("activeInMarketPlace", "==", true) ,
     orderBy("tokenId")
     );


    let result =  await getDocs(q)
   

    if (!result.empty) {
      dispatch(activeAdminNftSuccess(result));
    } else {
      dispatch(activeAdminNftSuccess(null));
    }
  };
};

export const activeAdminNftSuccess = (data) => ({
  type: ACTIVE_ADMIN_NFT,
  payload: data,
});

// export const addSpecialDrop = (nftId) => {
//     return async (dispatch) => {
//         await fireba/se.fir/estore()
//             .collection('specialDrops')
//             .add({
//                 nftItem: 'musicartNfts/' + nftId
//             })
//     }
// }
