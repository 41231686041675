import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/authReducer';
import walletReducer from './reducers/walletDataReducer';
import bsvRateReducer from './reducers/bsvRateReducer';
import nftReducer from './reducers/nftReducer';
import adminReducer from './reducers/adminReducer';
import audioReducer from './reducers/audioReducer';
import handCashReducer from './reducers/handCashReducer';

import { createWrapper } from 'next-redux-wrapper';

const rootReducer = combineReducers({
  authReducer: authReducer,
  walletReducer: walletReducer,
  bsvRateReducer: bsvRateReducer,
  nftReducer: nftReducer,
  adminReducer: adminReducer,
  audioReducer: audioReducer,
  handCashReducer: handCashReducer,
});

const initStore = (initialState = initState) => {
  return createStore(rootReducer, applyMiddleware(thunk));
};

export const wrapper = createWrapper(initStore);
