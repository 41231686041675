import { GET_NFT_DATA, UPDATE_ROOMS_DATA } from '../actions/nftActions';

const initState = {
  activeNftData: null,
  roomsData: [],
};

const nftReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NFT_DATA:
      return {
        ...state,
        activeNftData: action.payload,
      };
    case UPDATE_ROOMS_DATA:
      return {
        ...state,
        roomsData: action.payload,
      };
    default:
      return state;
  }
};

export default nftReducer;
