import apiConfig from '../config/apiConfig';
import {
  updateWalletAddress,
  updateWalletPath0Address,
  updateWalletPaymail,
  updateWalletBalance,
  updatewalletmnemonic,
  updatewallethistory,
} from '../store/actions/actionsMain';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore/lite';
import { firebaseFireStore } from '../config/firebaseFirestore';
import {
  updateHandCashProfile,
  updateHandCashBalance,
} from '../store/actions/actionsMain';
import { httpsCallable } from 'firebase/functions';
import { firebaseFunctions } from '../config/firebaseFunctions';
import { wrapper } from '../store/index';

export const getMaxNftSize = async () => {
  let snap = await getDoc(
    doc(firebaseFireStore, 'MusicArtGlobalVariables', 'maxNftSize'),
  );

  return {
    val:
      snap.exists &&
      snap.data() &&
      snap.data().size &&
      Number(snap.data().size) &&
      Math.floor(Number(snap.data().size) * 1048576),
    mb:
      snap.exists &&
      snap.data() &&
      snap.data().size &&
      Number(snap.data().size),
  };
};

export const getMaxNftBgSize = async () => {
  let snap = await getDoc(
    doc(firebaseFireStore, 'MusicArtGlobalVariables', 'maxNftBgSize'),
  );

  return {
    val:
      snap.exists &&
      snap.data() &&
      snap.data().size &&
      Number(snap.data().size) &&
      Math.floor(Number(snap.data().size) * 1048576),
    mb:
      snap.exists &&
      snap.data() &&
      snap.data().size &&
      Number(snap.data().size),
  };
};

export const updateGlobalVariableValue = async ({
  variableName,
  key,
  value,
}) => {
  await getDoc();
  await updateDoc(
    doc(firebaseFireStore, 'MusicArtGlobalVariables', variableName),
    { [key]: Number(value) },
  );
};

export const getUserName = async (id) => {
  const docd = await getDoc(doc(firebaseFireStore, 'MusicArtUsernames', id));
  if (docd.exists) {
    let dataObj = docd.data();
    if (dataObj && dataObj.userNameDisplay) {
      return dataObj.userNameDisplay;
    }
  }
  return null;
};

export const countDecimals = function (value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1]?.length || 0;
};

export const getwalletBal = async (walletid, dispatch) => {
  //wallet balance
  console.log('get wallet bal called');

  await apiConfig
    .get('/v1/metrics', {
      headers: {
        walletID: `${walletid}`,
      },
    })
    .then(async (res) => {
      console.log('res metrics api res', res.data.data.data.balance);
      dispatch(updateWalletBalance(res.data.data.data.balance / 100000000));
    })
    .catch((err) => {
      console.log('metrics api error', err, err.response);
      dispatch(updateWalletBalance(0));
    });
};

export const getwalletDetails = async (walletid, dispatch, userId) => {
  // console.log("get wallet details", apiConfig.defaults.headers);

  apiConfig
    .get('/v1/address', {
      headers: {
        walletID: `${walletid}`,
      },
    })
    .then((res) => {
      // console.log("getting address", res.data.data);
      dispatch(updateWalletAddress(res.data.data.address));
      dispatch(updateWalletPaymail(res.data.data.paymail));

      validatingPaymail(res.data.data.paymail, userId);
    })
    .catch((err) => {
      console.log('address error', err, err.response);
      if (err?.response?.data?.data?.msg?.includes("you don't have 00000000")) {
        createwallet('default', dispatch, userId);
      }
    });

  apiConfig
    .get('/v1/address?path=0', {
      headers: {
        walletID: `${walletid}`,
      },
    })
    .then((res) => {
      // console.log("getting address", res.data.data);
      dispatch(updateWalletPath0Address(res.data.data.address));
    })
    .catch((err) => {
      console.log('address error', err, err.response);
      if (err?.response?.data?.data?.msg?.includes("you don't have 00000000")) {
        createwallet('default', dispatch, userId);
      }
    });

  //wallet balance
  getwalletBal(walletid, dispatch);

  //wallet mnemonic
  apiConfig
    .get('/v1/mnemonic', {
      headers: {
        walletID: `${walletid}`,
      },
    })
    .then((res) => {
      dispatch(updatewalletmnemonic(res.data.data.mnemonic));

      // console.log("getting mnomonic",res);
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.data?.data?.msg?.includes("you don't have 00000000")) {
        createwallet('default', dispatch, userId);
      }
    });

  //wallet history
  apiConfig
    .get('/v1/history', {
      headers: {
        walletID: `${walletid}`,
      },
    })
    .then((res) => {
      if (res.data.data.histories) {
        dispatch(updatewallethistory(res.data.data.histories));
      } else {
        dispatch(updatewallethistory([]));
      }

      // console.log("getting history",res);
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.data?.data?.msg?.includes("you don't have 00000000")) {
        createwallet('default', dispatch, userId);
      }
    });
};

export const createwallet = async (name, dispatch, userId) => {
  console.log('creating wallet', userId);
  apiConfig
    .get('/v1/createWallet', {
      headers: {
        walletTitle: `${name}`,
      },
    })
    .then(async (res) => {
      console.log('wallet created');

      settingPaymail('00000000-0000-0000-0000-000000000000', userId);

      //create escrow address
      let createM34Address = httpsCallable(
        firebaseFunctions,
        'musicartCreateM34Address',
      );

      let apiRes = await createM34Address({
        userAuthToken: apiConfig.defaults.headers.common['authToken'],
      });

      getwalletDetails(res.data.data.walletID, dispatch, userId);
    })
    .catch((err) => {
      console.log('wallet error', err);
    });
};

const settingPaymail = async (walletid, userID) => {
  try {
    console.log('setting paymial call');
    apiConfig
      .get('/v1/address', {
        headers: {
          walletID: `${walletid}`,
        },
      })
      .then((res) => {
        let paymailName = res.data.data.paymail.substring(
          0,
          res.data.data.paymail.indexOf('@'),
        );

        let ref = doc(firebaseFireStore, 'payMailStore', paymailName);
        setDoc(ref, {
          userID: userID,
          walletID: walletid,
        });
      })
      .catch((err) => {
        console.log('setting paymail err');
      });

    apiConfig
      .get('/v1/allAddresses', {
        headers: {
          walletID: `${walletid}`,
        },
      })
      .then((res) => {
        let addresses = res.data.data.addressess;
        console.log('calling addresses api');
        let addressObj = [];
        addresses.map((ele, index) => {
          if (ele) {
            addressObj[index] = {
              address: ele,
              path: `m/44'/0'/0'/0/${index}`,
            };
          }
        });

        console.log('addressObj', addressObj);
        let ref = doc(
          firebaseFireStore,
          'pathStore',
          userID,
          'wallets',
          walletid,
        );
        setDoc(ref, {
          address: addressObj,
        });
        console.log('doc setted 88');
      })
      .catch((err) => {
        console.log('setting address err');
      });
  } catch (err) {
    console.log('setting paymail err', err);
  }
};

const validatingPaymail = async (paymail, userId) => {
  try {
    let paymailName = paymail.substring(0, paymail.indexOf('@'));
    console.log('validaing paymail', paymailName);
    const docRef = doc(firebaseFireStore, 'payMailStore', paymailName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists() && docSnap.data() && docSnap.data().userID) {
      console.log('paymail exits');

      //validating addresses
      const docRef2 = doc(
        firebaseFireStore,
        'pathStore',
        userId,
        'wallets',
        '00000000-0000-0000-0000-000000000000',
      );

      const docSnap2 = await getDoc(docRef2);

      if (docSnap2.exists() && docSnap2.data() && docSnap2.data()?.address) {
        // console.log('addresses exits', docSnap2.data());

        return;
      } else {
        // console.log('setting addresses in pathstore col');
        settingPaymail('00000000-0000-0000-0000-000000000000', userId);
      }
    } else {
      console.log('setting paymail in col');
      // setting doc
      settingPaymail('00000000-0000-0000-0000-000000000000', userId);
    }
  } catch (err) {
    console.log('valdiate err', err);
  }
};

export const getHandCashWalletDetails = async (id, dispatch) => {
  //m34 address retrive
  console.log('getHandCashWalletDetails');

  const docRef = doc(firebaseFireStore, 'musicartM34Addresses', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists() && !docSnap.data()) {
    // console.log('creating m34 address!');

    let createM34Address = httpsCallable(
      firebaseFunctions,
      'musicartCreateM34Address',
    );

    let m34apiRes = await createM34Address({
      userAuthToken: apiConfig.defaults.headers.common['authToken'],
    });
    // console.log('m34 m34apiRes', m34apiRes);
  } else {
    // console.log('m34 address exits');
  }

  //update wallet bal
  let handCashUpdateApi = httpsCallable(
    firebaseFunctions,
    'updateHandCashWallet',
  );

  let apiRes = await handCashUpdateApi();
  console.log('getHandCashWalletDetails apiRes', apiRes);
  if (apiRes.data.data) {
    console.log('apiRes 444444', apiRes.data.data);

    if (apiRes.data.data.currentProfile) {
      dispatch(updateHandCashProfile(apiRes.data.data.currentProfile));
    }

    if (apiRes.data.data.balance) {
      dispatch(updateHandCashBalance(apiRes.data.data.balance));
    }
  }
};

export const verifyUserHandcashHandle = async (userId) => {
  const docRef = doc(firebaseFireStore, 'handCashWallets', userId);
  const docSnap = await getDoc(docRef);
  if (
    docSnap.exists() &&
    docSnap.data() &&
    docSnap.data()?.currentProfile?.publicProfile?.handle
  ) {
    console.log(
      'handle exits!',
      docSnap.data()?.currentProfile?.publicProfile?.handle,
    );
    return true;
  } else {
    return null;
  }
};

export const redeemNfts = async (dataArr) => {
  try {
    console.log('calling redeem api');
    const response = await apiConfig.post('/v1/redeem', { dataArray: dataArr });
    console.log('nft redeem called');
    return response.data;
  } catch (error) {
    console.log('error', error, error.response.data.data.msg);
    return {
      message: error?.response?.data?.data?.msg
        ? error.response.data.data.msg
        : 'An error occured',
      status: 'error',
    };
  }
};
